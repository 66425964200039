import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import apiService from '../../core/apiService';
import moment from 'moment';
import { useNavigate } from "react-router-dom";


const HappyHourDrink = ({
	restaurant_id
}) => {
	const [dataLoading, setDataLoading] = useState(false);
	const [wineLists, setWineList] = useState(null);
	const [beersLists, setBeerList] = useState(null);
	const [ciderLists, setCiderList] = useState(null);
	const [otherDrinkList, setOtherDrinkList] = useState(null);
	const [listError, setListError] = useState();
	const [wineTypes, setWineType] = useState([]);
	const [wineSubTypes, setWineSubType] = useState([]);
	const [tabKey, setTabKey] = useState('1');
	const navigate = useNavigate();

	const getDrinkList = (args) => {
		setDataLoading(true);
		let params = { type_id: 2, special_id: 3, restaurant_id: restaurant_id, drinktype_id: args?.tabKey };
		if (args?.filters) {
			params = { ...params, ...args.filters };
		}
		apiService.get(`/restaurants/specials`, params, ({ err, data }) => {

			
			let temp = data
			if (err) return setDataLoading(false),
				 setWineList([]), setBeerList([]), setCiderList([])

			apiService.get(`/listDrinkNames`, { drink_type: tabKey }, ({ err, data }) => {
				console.log(data.data, 'datdatadta')
				let temp1 = data


			
				apiService.get(`/listTagss`, { type: tabKey }, ({ err, data }) => {
					if (err) return console.log(err);
              
					let drinkCategories = []
					let filterTags = []
					let filterWine = []
					let filetrWineName = []
					temp.data.map(item => {
						console.log(item,'itewm')
						if (!(item.restauranttag?.tags_ids == null)){ 
							let tagId = item.restauranttag.tags_ids					
							filterTags = data.data.filter(tag => eval(tagId).includes(tag.id))
								.map(filteredTag => filteredTag.tag_name);
						
							item.Special_tag = filterTags.join(',')
						}
							if (!(item.restauranttag?.wine_type_ids == null)) {
							let winetypeid = item.restauranttag?.wine_type_ids
						
							filterWine = wineTypes.filter(wine => eval(winetypeid).includes(wine.id))
								.map(filetr => filetr.wine_name)
							item.winetypes = filterWine.join(',')
						}
						
							if (!(item.restauranttag?.wine_ids == null)) {
								let winenameid = item.restauranttag?.wine_ids
	
								filetrWineName = wineSubTypes.filter(wine => eval(winenameid).includes(wine.id))
									.map(filetr => filetr.winesubtype_name)
						   
								item.winenames = filetrWineName.join(',')
								 }
							
	
							if (!(item.restauranttag?.drink_ids == null)){ 
							let drinkId = item.restauranttag?.drink_ids
							console.log(drinkId, 'drinkid')
							drinkCategories = temp1.data.filter(food => eval(drinkId).includes(food.id))
								.map(filteredDrink => filteredDrink.drink_name);
							item.Standard_Tags = drinkCategories.join(',')
						}



					});
					console.log(temp.data,'gfghh')
					if (tabKey === '1') return setWineList(temp.data);
					if (tabKey === '2') return setBeerList(temp.data);
					if (tabKey === '3') return setCiderList(temp.data);
					if (tabKey === '4') return setOtherDrinkList(temp.data);
				
				})
			});
			// setTotal(data.data.count)
			setDataLoading(false);
			




		})
	}

	useEffect(() => {
		setDataLoading(true);
		apiService.get('/get-winetype', {}, ({ err, data }) => {
			if (err) return setDataLoading(false), console.log(err);
		
			setWineType(data.data);
	
		});
	apiService.get('/get-winesubtype', {  }, ({ err, data }) => {
			if (err) return setDataLoading(false), console.log(err);
			setWineSubType(data.data);
	
		});

		getDrinkList({ tabKey });
	}, [tabKey ,wineSubTypes.length, wineTypes.length]);
	const handleSelect = (key) => {
		setTabKey(key);
		// apiService.get(`/restaurants/specials`, { ...{ type_id: 2, special_id: 3, drinktype_id: key }, restaurant_id: restaurant?.id }, ({ err, data }) => {
		// 	if (err) return setDataLoading(false), setListError(err.error);
		// 	if (key === '1') return setWineList(data.data);
		// 	if (key === '2') return setBeerList(data.data);
		// 	if (key === '3') return setCiderList(data.data);
		// 	setDataLoading(false);
		// })
	}


	const handleDelete = (id) => {
		apiService.remove(`/restaurants/specials/food/${id}`, ({ err }) => {
			if (err) return setDataLoading(false), setListError(err.error);
			setWineList(wineLists.filter((i) => (i.id !== id)));
			setBeerList(beersLists.filter((i) => (i.id !== id)));
			setCiderList(ciderLists.filter((i) => (i.id !== id)));
			setOtherDrinkList(otherDrinkList.filter((i) => (i.id !== id)));
		});
	}





	return (
		<>
			<div className="tab-content" id="common-tabs-section">
				<Tabs defaultActiveKey="1" onSelect={handleSelect} id="daily-special-tabs" className="mb-3">
					<Tab eventKey={1} title="Wine">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
											<th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Region</th>
											<th>Red/ White</th>
											<th>Wine Type</th>
											<th>Container Type</th>
											<th>Size</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Winetype Tags</th>
											<th>Winename Tags</th>
											
										</tr>
									</thead>
									<tbody className={wineLists?.length > 0 ? '' : 'empty_row'}>
										{
											wineLists == null ?
												<tr>
													<td colSpan={12}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												wineLists?.length ?
													wineLists?.map((item) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
															<td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>
															{/* <td>{moment(item.restaurantspecialshour?.start_time, "HH:mm:ss").format("hh:mm A")}</td>
															<td>{moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("hh:mm a")}</td> */}
															<td>{item.region?.region_name}</td>
															<td>{item.winetype?.wine_name || "Multiple Types"}</td>
															<td>{item.winesubtype?.winesubtype_name || "Multiple Wine Names"}</td>
															<td>{item.container?.container_name}</td>
															<td>{item.size?.size_name}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>${item.discount_amount}</td>
																<td >{item.item_description}</td>
																<td>{item.Special_tag}</td>
															<td>{item.winetypes}</td>
															<td>{item.winenames}</td>
														</tr>
													)) :
													<tr>
														<td colspan="13">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
					<Tab eventKey={2} title="Beer">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
											<th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Name</th>
											<th>Container type</th>
											<th>Size</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
											
										</tr>
									</thead>
									<tbody className={beersLists?.length > 0 ? '' : 'empty_row'}>
										{
											beersLists == null ?
												<tr>
													<td colSpan={12}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												beersLists?.length ?
													beersLists?.map((item) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
															<td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>
															{/* <td>{moment(item.restaurantspecialshour?.start_time, "HH:mm:ss").format("hh:mm A")}</td>
															<td>{moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("hh:mm a")}</td> */}
															<td>{item.drinkname?.drink_name || 'Multiple beers'}</td>
															<td>{item.container?.container_name}</td>
															<td>{item.size?.size_name}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>${item.discount_amount}</td>
																<td >{item.item_description}</td>
																<td>{item.Special_tag}</td>
															<td>{item.Standard_Tags}</td>
														</tr>
													)) :
													<tr>
														<td colspan="12">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
					<Tab eventKey={3} title="Cider">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
											<th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Name</th>
											<th>Container Type</th>
											<th>Size</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
											
										</tr>
									</thead>
									<tbody className={ciderLists?.length > 0 ? '' : 'empty_row'}>
										{
											ciderLists == null ?
												<tr>
													<td colSpan={12}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												ciderLists?.length ?
													ciderLists?.map((item) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
															<td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>
															{/* <td>{moment(item.restaurantspecialshour?.start_time, "HH:mm:ss").format("hh:mm A")}</td>
															<td>{moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("hh:mm a")}</td> */}
															<td>{item.drinkname?.drink_name || 'Multiple Ciders'}</td>
															<td>{item.container?.container_name}</td>
															<td>{item.size?.size_name}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>${item.discount_amount}</td>
																<td >{item.item_description}</td>
																<td>{item.Special_tag}</td>
															<td>{item.Standard_Tags}</td>
														</tr>
													)) :
													<tr>
														<td colspan="12">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
					<Tab eventKey={4} title="Other">
						<div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Day</th>
											<th>Happy Hour 1</th>
											<th>Happy Hour 2</th>
											<th>Name</th>
											<th>Discount Type</th>
											<th>Price</th>
											<th>Description</th>
											<th>Special Tags</th>
											<th>Standard Tags</th>
											
										</tr>
									</thead>
									<tbody className={otherDrinkList?.length > 0 ? '' : 'empty_row'}>
										{
											otherDrinkList == null ?
												<tr>
													<td colSpan={10}>
														<div className='table-inner-loader'>
															<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
														</div>
													</td>
												</tr> :
												otherDrinkList?.length ?
													otherDrinkList?.map((item) =>
													(
														<tr>
															<td>{item.day?.day_name}</td>
															<td>{item.restaurantspecialshour.all_day == 1?"All Day": item.restaurantspecialshour?.start_time + " - " + item.restaurantspecialshour?.end_time  }</td>
															<td>{item.restaurantspecialshour.start_time2 ? item.restaurantspecialshour?.start_time2 + " - " + item.restaurantspecialshour?.end_time2 :"" }</td>
															{/* <td>{moment(item.restaurantspecialshour?.start_time, "HH:mm:ss").format("hh:mm A")}</td>
															<td>{moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("hh:mm a")}</td> */}
															<td>{item?.drink_name  || "Multiple Others"}</td>
															<td className='discount_type'>{item.discount_type}</td>
															<td>${item.discount_amount}</td>
																<td >{item.item_description}</td>
																<td>{item.Special_tag}</td>
															<td>{item.Standard_Tags}</td>
														</tr>
													)) :
													<tr>
														<td colspan="10">
															No records found!
														</td>
													</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
				</Tabs>
			</div>
		</>
	);
};

export default HappyHourDrink;